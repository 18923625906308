// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // apiUrl: 'http://localhost:9000/api',
  apiUrl: 'https://cleanerslistadmin.com:9000/maidPicker',
  chatUrl: 'https://cleanerslistadmin.com:5000/api/cleanerslist',
  herokuApiUrl: 'https://safe-depths-94053.herokuapp.com/maidPicker',
  ACCESSKEY: `AKIA2ZRCKHYHFZWBLPGH`,
  SECRETKEY: `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`,
  BUCKET: `property-manager-images`,
  FOLDER: `Property/`,
};