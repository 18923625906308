import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  display(value: boolean) {
    this.status.next(value);
    console.log("Loader Value", value);
  }
  show() {
    this.status.next(true);
  }
  hide() {
    this.status.next(false);
  }
  constructor() {}
}
