import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GenericService } from "app/services/generic.service";
import { LoaderService } from "app/loader.service";
import { ToastService } from "app/services/toast.service";
import { ApiNames } from "app/utility/constants";

declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  loginParameter: {};
  userType = "";
  deviceId = "";
  deviceType = "";
  

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loader: LoaderService,
    private generic: GenericService,
    private toast: ToastService
  ) { }
 
  ngOnInit() {
    const token = localStorage.getItem("auth-token");
    const id = localStorage.getItem("auth-id");
    if (token && id) {
      this.router.navigate(["/dashboard"], { replaceUrl: true });
    } else {
      this.router.navigate(["/login"]);
    }
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]

    })
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // if (
    //   this.loginForm.value.email === "admin@gmail.com" &&
    //   this.loginForm.value.password === "admin"
    // ) {                               
      this.loader.show();
      this.generic
      .save("login",{ "email" : this.loginForm.value.email, "password": this.loginForm.value.password, userType : "admin"})
      
        .subscribe(res => {
         // console.log('response is----->>>>', res);
         this.loader.hide();
         if (res.errorCode == 0 && res.isSuccess == "true") {
           const { data: { authToken, userId } } = res;
           localStorage.setItem("auth-token", authToken);
           localStorage.setItem("auth-id", userId);
           this.router.navigate(["/dashboard"]);
         } else {
          this.toast.error(res.errorMessage);
         }
        }, err => {
          this.loader.hide();
          const { error: { errorMessage } = '' } = err;
          if (errorMessage) {
            this.toast.error(errorMessage);
          }
        });
    // } else {
    //   this.toast.error('Invalid Credentials');
    // }
  }
}
