import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from "app/services/generic.service";
import { LoaderService } from "app/loader.service";
import { ApiNames } from "app/utility/constants";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from "app/services/toast.service";
import { type } from 'os';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  showSetPassword: boolean;
  setPasswordForm: FormGroup;
  sendRequestForm: FormGroup;
  submitted = false;
  userId: string = '';
  showEmailInput: boolean;
  checkAdmin: any;

  constructor(private route: ActivatedRoute, private loaderService: LoaderService, private generic: GenericService, private formBuilder: FormBuilder, private router: Router, private toast: ToastService) {
    console.log("Inside SetPassowrd");
  }

  ngOnInit() {
    this.setPasswordForm = this.formBuilder.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });

    this.sendRequestForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });

    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        this.userId = params.id;
        this.checkAdmin = params.isAdmin;
        let url;
        if (this.checkAdmin) {
          url = ApiNames.ADMIN_TOKEN_EXPIRY
        } else {
          url = ApiNames.TOKEN_EXPIRY
        }

        this.loaderService.show();
        this.generic
          .save(url, params)
          .subscribe(res => {
            const { response: { isExpires }, message } = res;
            this.loaderService.hide();
            if (isExpires) {
              this.showSetPassword = false;
              this.toast.info(message);
            } else {
              this.showSetPassword = true;
              this.router.navigate(["/set-password"]);
            }
          }, err => {
            const { error: { message } } = err;
            this.loaderService.hide();
            this.toast.error(message);
          });
      } else {
        this.showSetPassword = true;
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.setPasswordForm.controls;
  }

  get fb() {
    return this.sendRequestForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.setPasswordForm.invalid) {
      return;
    }

    if (this.setPasswordForm.value.password !== this.setPasswordForm.value.confirmPassword) {
      this.toast.error("Password and Confirm Password didn't match.");

      return;
    }

    this.loaderService.show();
    const formData = this.setPasswordForm.value;
    const data = { ...formData, id: this.userId }
    let url;
    if (this.checkAdmin) {
      url = ApiNames.ADMIN_SET_PASSWORD
    } else {
      url = ApiNames.SET_PASSWORD
    }
    this.generic.save(url, data)
      .subscribe(res => {
        this.loaderService.hide();
        const { message } = res;
        this.toast.success(message);
        this.router.navigateByUrl("/login");
      }, err => {
        this.loaderService.hide();
        const { error: { message } } = err;
        this.toast.error(message);
      });
  }

  sendRequest() {
    this.submitted = true;
    if (this.sendRequestForm.invalid) {
      return;
    }

    let url;
    if (this.checkAdmin) {
      url = ApiNames.ADMIN_SEND_EMAIL
    } else {
      url = ApiNames.SEND_EMAIL
    }
    this.loaderService.show();
    this.generic.save(url, this.sendRequestForm.value)
      .subscribe(res => {
        this.loaderService.hide();
        const { message } = res;
        this.toast.success(message);
        this.router.navigateByUrl("/login");
      }, err => {
        this.loaderService.hide();
        const { error: { message } } = err;
        this.toast.error(message);
      });
  }

}
