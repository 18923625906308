export class ApiNames {

  public static readonly AWS_TESTING = "http://ec2-3-82-151-141.compute-1.amazonaws.com:8010/maidPicker/";
  public static readonly AWS_PRODUCTION = "http://ec2-3-82-151-141.compute-1.amazonaws.com:8000/maidPicker/";
  public static readonly HEROKU = "https://safe-depths-94053.herokuapp.com/maidPicker/";
  public static readonly ADD_PROPERTY = 'property/add';
  public static readonly LIST_PROPERTY = 'property/list';
  public static readonly GET_PROPERTY = 'property';
  public static readonly UPDATE_PROPERTY = 'property/edit';
  public static readonly DELETE_PROPERTY = 'property/delete';
  public static readonly ADD_PROPERTY_MANAGER = 'property-manager/add';
  public static readonly LIST_PROPERTY_MANAGER = 'property-manager/list';
  public static readonly GET_PROPERTY_MANAGER = 'property-manager';
  public static readonly UPDATE_PROPERTY_MANAGER = 'property-manager/edit';
  public static readonly DELETE_PROPERTY_MANAGER = 'property-manager/delete';
  public static readonly ADD_SERVICE_PROVIDER = 'service-provider/add';
  public static readonly LIST_SERVICE_PROVIDER = 'service-provider/list';
  public static readonly GET_SERVICE_PROVIDER = 'service-provider';
  public static readonly UPDATE_SERVICE_PROVIDER = 'service-provider/edit';
  public static readonly DELETE_SERVICE_PROVIDER = 'service-provider/delete';
  public static readonly TOKEN_EXPIRY = 'property-manager/check-token';
  public static readonly SET_PASSWORD = 'property-manager/set-password';
  public static readonly SEND_EMAIL = 'property-manager/send-email';
  public static readonly ADD_BILLING_CONTACT = 'billing-contact/add';
  public static readonly LIST_BILLING_CONTACT = 'billing-contact/list';
  public static readonly GET_BILLING_CONTACT = 'billing-contact';
  public static readonly UPDATE_BILLING_CONTACT = 'billing-contact/edit';
  public static readonly DELETE_BILLING_CONTACT = 'billing-contact/delete';
  public static readonly ADD_HOUSE_CLEANING = 'house-cleaning/add';
  public static readonly ADD_CARPET_CLEANING = 'carpet-cleaning/add';
  public static readonly ADD_WINDOW_CLEANING = 'window-cleaning/add';
  public static readonly GET_PRICE = 'get-price';
  public static readonly UPDATE_HOUSE_CLEANING = 'house-cleaning/update';
  public static readonly UPDATE_CARPET_CLEANING = 'carpet-cleaning/update';
  public static readonly UPDATE_WINDOW_CLEANING = 'window-cleaning/update';
  //public static readonly ADD_ADMIN = 'admin/add';
  public static readonly ADD_ADMIN = 'addAdmin';
  public static readonly LIST_ADMIN = 'admin/list';
  public static readonly GET_ADMIN = 'admin';
  public static readonly UPDATE_ADMIN = 'admin/edit';
  public static readonly DELETE_ADMIN = 'admin/delete';
  public static readonly ADMIN_TOKEN_EXPIRY = 'admin/check-token';
  public static readonly ADMIN_SET_PASSWORD = 'admin/set-password';
  public static readonly ADMIN_SEND_EMAIL = 'admin/send-email';

  

  public static readonly GET_COUNT = 'dashboard/count';
  public static readonly LOGIN = 'account/login';
}
export class ProjectConstants {
  public static readonly SUCCESS = `success`;
  public static readonly ERROR = `error`;
  public static readonly RESULT = `result`;
  public static readonly DATA = `data`;

  public static readonly ACCESSKEY = `AKIA2ZRCKHYHFZWBLPGH`;
  public static readonly SECRETKEY = `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`;
  public static readonly BUCKET = `property-manager-images`;
  public static readonly FOLDER = `Property/`;

  // public static readonly ACCESSKEY = `AKIAJTAVQFKEFY6WHGAQ`;
  // public static readonly SECRETKEY = `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`;
  // public static readonly BUCKET = `cleanapphouse`;
  // public static readonly FOLDER = `cleanapphouse/`;
}
