import { Injectable } from "@angular/core";
declare var $: any;

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor() {}

  success(message) {
    $.notify(message, {
      type: "success",
      allow_dismiss: true,
      placement: {
        from: "top",
        align: "right",
      },
      title: "",
    });
  }

  error(message) {
    $.notify(message, {
      type: "danger",
      allow_dismiss: true,
      placement: {
        from: "top",
        align: "right",
      },
      title: "",
    });
  }

  info(message) {
    $.notify(message, {
      type: "info",
      allow_dismiss: true,
      placement: {
        from: "top",
        align: "right",
      },
      title: "",
    });
  }

  showNotification(message, url) {
    $.notify({
      message,
      url,
    }, {
      type: "success",
      allow_dismiss: true,
      placement: {
        from: "top",
        align: "right",
      },
      newestOnTop: false,
      delay: 0,
      url_target: '_self'
    });
  }
}
