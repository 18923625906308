import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GenericService } from "app/services/generic.service";
import { LoaderService } from "app/loader.service";
import { ToastService } from "app/services/toast.service";
import { ApiNames } from "app/utility/constants";

declare var $: any;
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  submitted = false;
  loginParameter: {};
  userType = "";
  deviceId = "";
  deviceType = "";
  showResetForm:boolean = false;
  

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loader: LoaderService,
    private generic: GenericService,
    private toast: ToastService
  ) { }
 
  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    })
    this.resetPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      otp: ["", Validators.required]
    })
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  get r() {
    return this.resetPasswordForm.controls;
  }

  onForgotPasswordSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
                    
    this.loader.show();
    this.generic
    .save("forgotPasswordAdmin",{ "email" : this.forgotPasswordForm.value.email, userType : "admin"})
    .subscribe(res => {
      // console.log('response is----->>>>', res);
      this.loader.hide();
      this.resetPasswordForm.controls['email'].setValue(this.forgotPasswordForm.value.email);
      this.showResetForm = true;
      this.toast.success(res.data.message);
    }, err => {
      const { error: { errorMessage } = '' } = err;
      this.loader.hide();
      if (errorMessage) {
        this.toast.error(errorMessage);
      }
    });

  }

  onResetPasswordSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
      this.loader.show();
      this.generic
      .save("resetPasswordAdmin",{ userType : "admin",  ...this.resetPasswordForm.value})
      .subscribe(res => {
        // console.log('response is----->>>>', res);
        this.loader.hide();
        this.router.navigate(["/login"]);
        this.toast.success(res.data.message);
      }, err => {
        const { error: { errorMessage } = '' } = err;
        this.loader.hide();
        if (errorMessage) {
          this.toast.error(errorMessage);
        }
      });
  }
}
