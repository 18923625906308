import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("auth-token");
    if (token) {
      const modified = httpRequest.clone({
        headers: httpRequest.headers.append('authorization', token)
      });
      return next.handle(modified);
    }
    return next.handle(httpRequest);
  }
}