import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseResponse } from "app/BaseResponse";
import { environment } from "environments/environment";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  private baseUrl: string = environment.apiUrl;
  private chatBaseUrl: string = environment.chatUrl;
  private herokuUrl: String = environment.herokuApiUrl;

  constructor(private http: HttpClient) { }

  save(endpoint, data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/${endpoint}`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('user', 'admin')
          .set('deviceType', 'Web')
      });
  }

  callHerokuAPI(endpoint, data): Observable<any> {
    return this.http
      .post(`${this.herokuUrl}/${endpoint}`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('user', 'admin')
          .set('deviceType', 'Web')
      });
  }

  saveData(endpoint, data,): Observable<any> {
    return this.http
     .post(endpoint, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('user', 'admin')
          .set('deviceType', 'Web')
      });
  }

  get(endpoint): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/${endpoint}`, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('user', 'admin')
          .set('deviceType', 'Web')
      });
  }
  get_V2(endpoint): Observable<any> {
    return this.http
      .get(`${this.chatBaseUrl}/${endpoint}`, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('user', 'admin')
        .set('deviceType', 'Web')
      });
  }

  update(endpoint, data): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/${endpoint}`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('user', 'admin')
          .set('deviceType', 'Web')
      });
  }

  delete(endpoint): Observable<any> {
    return this.http
      .delete(`${this.baseUrl}/${endpoint}`, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('user', 'admin')
          .set('deviceType', 'Web')
      });
  }
}
