import { Component } from "@angular/core";
import { LoaderService } from "app/loader.service";
import { Subscription } from "rxjs";
import { GenericService } from "app/services/generic.service";
import { ToastService } from "app/services/toast.service";
import * as moment from 'moment';
import { Router } from "@angular/router";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  showLoader: boolean;
  private subscription: Subscription;

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private generic: GenericService,
    private toast: ToastService) {}
  ngOnInit(): void {
    this.loaderService.status.subscribe((val: boolean) => {
      setTimeout(() => {
        this.showLoader = val;
      });
    });
    setInterval(() => {
      this.getEvents();
    }, 300000);
    this.getEvents();
  }

  getEvents() {
    let currentDelay = localStorage.getItem('getN');
    let call = 0;
    
    if (!currentDelay || (currentDelay && moment().isAfter(currentDelay))) {
      call = 1;
    }
    
    if (call) {
      this.generic.get('listNotificationsV3').subscribe(result => {
        let delay = moment().add(5, 'minutes').toISOString();
        localStorage.setItem('getN', delay);
        if (result && result.data.length) {
          result.data.forEach(each => {
            this.toast.showNotification(each.event, each.link);
          });
        }
      }, err => {
        console.log(err);
        const { error: { errorMessage } } = err;
        this.loaderService.hide();
        if (errorMessage == "jwt expired"){
          localStorage.setItem("auth-token", "");
          localStorage.setItem("auth-id", "");
          localStorage.setItem("providerid", "");
          this.router.navigate(["/login"]);
        }
        console.log("error value in listNotificationsV3",errorMessage)
      });
    }
  }
}
